import React from "react"
import { useTranslation } from "react-i18next"
import NavBar from "../components/layout/NavBar"
import {Helmet} from "react-helmet"

const Guidelines = () => {
    const { t } =  useTranslation()
    return (
        <>
            <Helmet>
                <meta charset="utf-8" />
                <title>{t('guide_mtitle')} | Wheat Discord Bot</title>
                <metadata
                name="description"
                content={t('guide_desc')}
                />
                <link rel="canonical" href="https://www.wheatbot.xyz/guidelines" />

                <meta property="og:site_name" content="Wheat Discord Bot" />
                <meta property="og:url" content="https://www.wheatbot.xyz/guidelines" />
                <meta property="og:title" content="Guidelines | Wheat Discord Bot" />
                <meta property="og:image" content={process.env.REACT_APP_URL_IMAGE} />
                <meta property="og:description" content="Latest Guidelines Statement of Wheat." />
            </Helmet>

            <NavBar bg_color="white" text_color="wheat-black" hover_color="wheat-yellow4"/>

            <div className="px-10 lg:px-32 py-20 font-text bg-white ">
                <div className="text-4xl md:text-6xl font-black font-extra">{t("guide_title")}</div>

                <div className="text-2xl pt-10">{t("guide_time")}</div>
                
                <div className="text-lg">{t("guide_p1")}</div>
                
                <br />
                
                <div className="text-lg">{t("guide_p2")}</div>

                <div className="text-lg">{t("guide_p3")}</div>

                <div className="text-3xl font-medium pt-5">{t("guide_p4")}</div>

                <ol className="list-inside list-decimal">
                    <li className="text-lg">{t("guide_p5")}</li>
                    <li className="text-lg">{t("guide_p6")}</li>
                    <li className="text-lg">{t("guide_p7")}</li>
                    <li className="text-lg">{t("guide_p8")}</li>
                    <li className="text-lg">{t("guide_p9")}</li>
                </ol>

                <div className="text-3xl font-medium pt-5">{t("guide_p10")}</div>
                <ol className="list-inside list-decimal">
                    <li className="text-lg">{t("guide_p11")}</li>
                    <li className="text-lg">{t("guide_p12")}</li>
                    <li className="text-lg">{t("guide_p13")}</li>
                    <li className="text-lg">{t("guide_p14")}</li>
                    <li className="text-lg">{t("guide_p15")}</li>
                </ol>
                
                <div className="text-2xl font-medium pt-5">{t("guide_p16")}</div>

                <div className="text-2xl font-medium pt-5">{t("guide_p17")}</div>

            </div>
        </>
    )
}

export default Guidelines

import React from "react"
import { useTranslation } from "react-i18next"
import NavBar from "../components/layout/NavBar"
import {Helmet} from "react-helmet"

const Disclaimer = () => {
    const { t } =  useTranslation()
    return (
        <>
            <Helmet>
                <meta charset="utf-8" />
                <title>{t('dis_mtitle')} | Wheat Discord Bot</title>
                <meta
                name="description"
                content={t('dis_desc')}
                />
                <link rel="canonical" href="https://www.wheatbot.xyz/disclaimer" />

                <meta property="og:site_name" content="Wheat Discord Bot" />
                <meta property="og:url" content="https://www.wheatbot.xyz/disclaimer" />
                <meta property="og:title" content="Disclaimer | Wheat Discord Bot" />
                <meta property="og:image" content={process.env.REACT_APP_URL_IMAGE} />
                <meta property="og:description" content="Latest Disclaimer Statement of Wheat." />
            </Helmet>

            <NavBar bg_color="white" text_color="wheat-black" hover_color="wheat-yellow4"/>

            <div className="px-10 lg:px-32 py-20 font-text bg-white ">
                <div className="text-4xl md:text-6xl font-black font-extra">{t("dis_title")}</div>

                <div className="text-2xl pt-10">{t("dis_time")}</div>
                
                <div className="text-lg">{t("dis_p1")}</div>
            
                <div className="text-3xl font-medium pt-5">{t("dis_p2")}</div>

                <div className="text-2xl font-medium pt-5">{t("dis_p3")}</div>
                <ul className="list-inside list-disc">
                    <li className="text-lg">{t("dis_p4")}</li>
                    <li className="text-lg">{t("dis_p5")}</li>
                    <li className="text-lg">{t("dis_p6")}</li>
                    <li className="text-lg">{t("dis_p7")}</li>
                    <li className="text-lg">{t("dis_p8")}</li>
                </ul>

                <div className="text-3xl font-medium pt-5">{t("dis_p9")}</div>
                <ul className="list-inside list-disc">
                    <li className="text-lg">{t("dis_p10")}</li>
                    <li className="text-lg">{t("dis_p11")}</li>
                    <li className="text-lg">{t("dis_p12")}</li>
                    <li className="text-lg">{t("dis_p13")}</li>
                    <li className="text-lg">{t("dis_p14")}</li>
                </ul>
                
                <div className="text-3xl font-medium pt-5">{t("dis_p15")}</div>

                <ul className="list-inside list-disc">
                    <li className="text-lg">{t("dis_p16")}</li>
                    <li className="text-lg">{t("dis_p17")}</li>
                </ul>

                <div className="text-3xl font-medium pt-5">{t("dis_p18")}</div>
                <ul className="list-inside list-disc">
                    <li className="text-lg">{t("dis_p19")}</li>
                    <li className="text-lg">{t("dis_p20")}</li>
                </ul>
                <div className="text-3xl font-medium pt-5">{t("dis_p21")}</div>
                <ul className="list-inside list-disc">
                    <li className="text-lg">{t("dis_p22")}</li>
                    <li className="text-lg">{t("dis_p23")}</li>
                </ul>
                <div className="text-3xl font-medium pt-5">{t("dis_p24")}</div>
                <ul className="list-inside list-disc">
                    <li className="text-lg">{t("dis_p25")}</li>
                    <li className="text-lg">{t("dis_p26")}</li>
                    <li className="text-lg">{t("dis_p27")}</li>
                </ul>
                <div className="text-3xl font-medium pt-5">{t("dis_p28")}</div>
                <ul className="list-inside list-disc">
                    <li className="text-lg">{t("dis_p29")}</li>
                    <li className="text-lg">{t("dis_p30")}</li>
                    <li className="text-lg">{t("dis_p31")}</li>
                </ul>

                <div className="text-3xl font-medium pt-5">{t("terms_p34")}</div>

                <div className="text-lg">
                {t("terms_p35")}<br />
                {t("terms_p36")}<br />
                {t("terms_p37")}
                </div>
                
                <div className="text-lg">
                    {t("terms_p38")}<br />
                    {t('terms_p39')} <strong>coolscp2005@gmail.com</strong></div>

            </div>
        </>
    )
}

export default Disclaimer

import React from "react"
import { useTranslation } from "react-i18next"
import NavBar from "../components/layout/NavBar"
import {Helmet} from "react-helmet"

const Privacy = () => {
    const { t } = useTranslation()

    return (
        <>
            <Helmet>
                <meta charset="utf-8" />
                <title>{t('privacy_mtitle')} | Wheat Discord Bot</title>
                <metadata
                name="description"
                content={t('privacy_desc')}
                />
                <link rel="canonical" href="https://www.wheatbot.xyz/privacy" />

                <meta property="og:site_name" content="Wheat Discord Bot" />
                <meta property="og:url" content="https://www.wheatbot.xyz/privacy" />
                <meta property="og:title" content="Privacy Policy | Wheat Discord Bot" />
                <meta property="og:image" content={process.env.REACT_APP_URL_IMAGE} />
                <meta property="og:description" content="Latest Privacy Policy Statement of Wheat." />
            </Helmet>
            
            <NavBar bg_color="white" text_color="wheat-black" hover_color="wheat-yellow4"/>

            <div className="px-10 lg:px-32 py-20 font-text bg-white ">
                <div className="text-4xl md:text-6xl font-black font-extra">{t("privacy_title")}</div>

                <div className="text-2xl pt-10">{t("privacy_time")}</div>
                
                <div className="text-lg">{t("privacy_p1")}</div>
            
                <div className="text-3xl font-medium pt-5">{t("privacy_p2")}</div>
                
                <div className="text-lg">{t("privacy_p3")}</div>
                
                <ul className="list-inside list-disc">
                    <li className="text-lg">{t("privacy_p4")}</li>
                    <li className="text-lg">{t("privacy_p5")}</li>
                    <li className="text-lg">{t("privacy_p6")}</li>
                </ul>

                <div className="text-3xl font-medium pt-5">{t("privacy_p7")}</div>
                <ul className="list-inside list-disc">
                    <li className="text-lg">{t("privacy_p8")}</li>
                    <li className="text-lg">{t("privacy_p9")}</li>
                    <li className="text-lg">{t("privacy_p10")}</li>
                    <li className="text-lg">{t("privacy_p11")}</li>
                </ul>

                <div className="text-3xl font-medium pt-5">{t("privacy_p12")}</div>
                <div className="text-lg">{t("privacy_p13")}</div>
                <div className="text-2xl font-normal pt-5">{t("privacy_p14")}</div>
                <div className="text-lg">{t("privacy_p15")}</div>
                <ul className="list-inside list-disc">
                    <li className="text-lg">{t("privacy_p16")}</li>
                    <li className="text-lg">{t("privacy_p17")}</li>
                    <li className="text-lg">{t("privacy_p18")}</li>
                    <li className="text-lg">{t("privacy_p19")}</li>
                    <li className="text-lg">{t("privacy_p20")}</li>
                    <li className="text-lg">{t("privacy_p21")}</li>
                    <li className="text-lg">{t("privacy_p22")}</li>
                    <li className="text-lg">{t("privacy_p23")}</li>
                    <li className="text-lg">{t("privacy_p24")}</li>
                    <li className="text-lg">{t("privacy_p25")}</li>
                </ul>

                <div className="text-2xl font-normal pt-5">{t("privacy_p26")}</div>
                <div className="text-lg">{t("privacy_p27")}</div>
                <ul className="list-inside list-disc">
                    <li className="text-lg">{t("privacy_p28")}</li>
                    <li className="text-lg">{t("privacy_p29")}</li>
                    <li className="text-lg">{t("privacy_p30")}</li>
                    <li className="text-lg">{t("privacy_p31")}</li>
                    <li className="text-lg">{t("privacy_p32")}</li>
                </ul>

                <div className="text-2xl font-normal pt-5">{t("privacy_p33")}</div>
                <div className="text-lg">{t("privacy_p34")}</div>
                <ul className="list-inside list-disc">
                    <li className="text-lg">{t("privacy_p35")}</li>
                    <li className="text-lg">{t("privacy_p36")}</li>
                    <li className="text-lg">{t("privacy_p37")}</li>
                    <li className="text-lg">{t("privacy_p38")}</li>
                    <li className="text-lg">{t("privacy_p39")}</li>
                </ul>

                <div className="text-3xl font-medium pt-5" >{t("privacy_p40")}</div>
                <div className="text-2xl">{t("privacy_p41")}</div>
                <ul className="list-inside list-disc">
                    <li className="text-lg">{t("privacy_p42")}</li>
                    <li className="text-lg">{t("privacy_p43")}</li>
                    <li className="text-lg">{t("privacy_p44")}</li>
                    <li className="text-lg">{t("privacy_p45")}</li>
                    <li className="text-lg">{t("privacy_p47")}</li>
                    <li className="text-lg">{t("privacy_p48")}</li>
                </ul>

                <div className="text-3xl font-medium pt-5">{t("privacy_p49")}</div>
                <ul className="list-inside list-disc">
                    <li className="text-lg">{t("privacy_p50")}</li>
                    <li className="text-lg">{t("privacy_p51")}</li>
                    <li className="text-lg">{t("privacy_p52")}</li>
                    <li className="text-lg">{t("privacy_p53")}</li>
                </ul>

                <div className="text-3xl font-medium pt-5">{t("privacy_p54")}</div>
                <ul className="list-inside list-disc">
                    <li className="text-lg">{t("privacy_p55")}</li>
                    <li className="text-lg">{t("privacy_p56")}</li>
                    <li className="text-lg">{t("privacy_p57")}</li>
                </ul>

                <div className="text-3xl font-medium pt-5">{t("privacy_p58")}</div>
                <ul className="list-inside list-disc">
                    <li className="text-lg">{t("privacy_p59")}</li>
                    <li className="text-lg">{t("privacy_p60")}</li>
                </ul>

                <div className="text-3xl font-medium pt-5">{t("privacy_p61")}</div>
                <ul className="list-inside list-disc">
                    <li className="text-lg">{t("privacy_p62")} <a href="https://www.mongodb.com/legal/privacy-policy"> MongoDB</a>)</li>
                    <li className="text-lg">{t("privacy_p63")}</li>
                    <li className="text-lg">{t("privacy_p64")}</li>
                </ul>

                <div className="text-3xl font-medium pt-5">{t("privacy_p65")}</div>
                <div className="text-lg">{t("privacy_p66")}</div>

                <div className="text-3xl font-medium pt-5">{t("privacy_p67")}</div>
                <div className="text-lg">
                {t("privacy_p68")}<br />
                {t("privacy_p69")}<br />
                {t("privacy_p70")}
                </div>
                
                <div className="text-lg">
                    {t("privacy_p71")}<br />
                    {t('privacy_p72')} <strong>coolscp2005@gmail.com</strong></div>
            </div>
        </>
    )
}

export default Privacy

import Footer from "../layout/Footer"
import {Route} from 'react-router-dom'

const PublicRoute = ({component:Component,...rest}) => {
    return (
        <>
            <Route {...rest} render={props => {
                return (
                <>
                    <Component {...rest} {...props} /> 
                    <Footer bg_color="wheat-black" border_color="wheat-yellow2" text_color="white" slo_color="wheat-yellow3" light_color="wheat-yellow1"/>
                </>
            )}} />
        </>
    )
}

export default PublicRoute

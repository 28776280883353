import { useTranslation } from "react-i18next"
import NavBar from '../components/layout/NavBar'
import {Link} from 'react-router-dom'
import { useState } from "react"
import WHEAT from '../assets/wheat_official.svg'
import thefool from '../assets/thefool.png'
import sagittarius from '../assets/sagittarius.png'
import duser from '../assets/duser.png'
import apod from '../assets/apod.jpg'
import ftelling from '../assets/ftelling.png'
import astronomy from '../assets/astronomy.png'
import randomm from '../assets/random.png'
import {Helmet} from "react-helmet"

const Homepage = () => {
    const { t } = useTranslation()

    const [fortune, setFortune] = useState('etarot')

    const [random, setRandom] = useState('eloremipsum')
    
    const changeFortune = (n) => {
        setFortune(n)
    }

    const changeRandom = (n) => {
        setRandom(n)
    }

    let commandText = {}

    commandText['etarot'] = {
        "command": "etarot",
        "reply": (
            <>
                <div className='font-bold'>⁘ Hello, lá bài Tarot của bạn là ...</div>
                <div className='text-xl my-2 font-bold'>The Fool!</div>
                <div className='font-bold mb-2'>Từ khóa</div>
                <div>Khởi đầu, Khờ dại, Tự phát, Tinh thần thoải mái</div>
                <div className='font-bold my-2'>Mô tả bài</div>
                <div>
                    The Fool là lá bài đầu tiên của bộ bài Tarot, nó mang một năng lượng vô hạn. Trong lá bài, hình ảnh chàng trai trẻ đứng bên bờ vực, không hề để ý đến thế giới xung quanh, và chính cậu ta đang tạo ra một cuộc hành trình mới cho mình. Cậu nhìn thẳng lên bầu trời và vũ trụ, không quan tâm đến việc không biết bước tiếp theo ở đâu. Trên vai cậu có mang một chiếc túi xách nhỏ chứa đựng mọi thứ cậu ta cần, không hề nhiều. Bông hoa trắng trên tay trái đã nói lên sự ngây thơ và khờ dại. Dưới chân là một chú chó trắng, như một sự trung thành và bảo vệ cho chủ nhân của nó, nhưng chàng khờ của chúng ta đang không để ý, cậu đang nghĩ về cuộc thám hiểm của bản thân mình.
                </div>
                <div className='font-bold my-2'>Ý nghĩa</div>
                <div>
                    Là một lá của sự khởi đầu, cơ hội và tiềm tăng. Như chàng trai trong bài, bạn đang bắt đầu một điều gì mới, như đứng trên vực núi, bước tiếp theo có thể ở bất cứ đâu. Cho dù bạn không biết mình sẽ như thế nào, nhưng hãy đi tiếp, theo con tim của mình dù nó điên rồ đến như thế nào. Hãy tin vào nơi Vũ trụ sẽ dẫn bạn tới. Chàng khờ này đang ủng hộ bạn tạo ra một sự đổi mới, mới mẻ và thú vị trong suy nghĩ. Bỏ lại đi những lo âu và mạnh dạn tiến lên phía trước. Đó sẽ là trải nghiệm mới, trưởng thành hơn, phát triển hơn qua các cuộc phiêu lưu. ĐÂY CHÍNH LÀ THỜI KHẮC BẠN CẦN LÀM! Dù bạn không có đủ hành trang hay chưa chuẩn bị cho những gì sẽ xảy ra tiếp. Bạn không cần đợi điều gì, không cần phải để cả bản đồ trong tay mình mới bắt đầu mà hãy khởi hành đi, với những gì mình đã có. Đừng đợi ai “bật đèn xanh” mà hãy tự tạo cho mình một tín hiệu đèn! Hãy dùng sự sáng tạo và kì diệu của mình trong thời điểm. Hãy thử sống như một đứa trẻ, vô tư, vô lo, tự do tự tại. Hãy nghe theo Vũ trụ chỉ dẫn và tiếp nhận.
                </div>
                <img src={thefool} alt="" className='pt-3 max-w-1/2'/>
            </>
        )
    }

    commandText['ezodiac'] = {
        "command":"ezodiac 20/09",
        "reply": (
            <>
                <div className='font-bold'>⋗ Cung hoàng đạo thứ: 9</div>
                <div className='text-xl my-2 font-bold'>♐︎ Nhân Mã - Sagittarius</div>
                <div className='font-bold mb-2'>∴ Đặc điểm</div>
                <div>⋄ Biểu tượng: Một nhân mã đang giương cung tên</div>
                <div>⋄ Kinh độ Hoàng đạo: 240° đến 270°</div>
                <div>⋄ Thời gian: 23 tháng 11 đến 21 tháng 12</div>
                <div>⋄ Đối lập với: Song Tử</div>    
                <div className='font-bold my-2'>∴ Tính chất</div>   
                <div>⋯ Tính chất: Biến đổi</div>
                <div>⋯ Từ khóa: Khả năng thích ứng, linh hoạt, tháo vát</div>
                <div className='font-bold my-2'>∴ Nguyên tố</div>
                <div>⋯ Thuộc nguyên tố: Lửa</div>
                <div>⋯ Từ khóa: Nhiệt tình, nỗ lực thể hiện bản thân, niềm tin</div>
                <div className='font-bold my-2'>∴ Thiên thể Cai trị</div>
                <div>⋇ Cổ điển: Sao Mộc</div>
                <div>⋇ Hiện đại: Sao Mộc</div>
                <div className='font-bold my-2'>∴ Phẩm giá Bản chất</div>
                <div>≫ Phẩm giá: Sao Mộc</div>
                <div>≫ Bất lợi: Sao Thủy</div>
                <div>≫ Đắc địa: Không có</div>
                <div>≫ Suy thoái: Không có</div>

                <img src={sagittarius} alt="" className='pt-3 max-w-1/2'/>
            </>
        )
    }

    commandText['enumerology'] = {
        "command":"enumerology 16/09/2002",
        "reply": (
            <>
                <div className='text-xl my-2 font-bold'>▩ Hello, số Chủ Đạo của bạn là số 2</div>
                <div>Chỉ có ngày sinh có tổng các chữ số là 20 mới có thể có con số chủ đạo là 2. Vì vậy, những người mang con số 2 này (và con số 22/4) rất ít so với các con số khác. Vậy nên không có gì ngạc nhiên khi cả hai con số này đều gợi ý một ý nghĩa đặc biệt. Những người mang con số chủ đạo 2 nhìn chung là những người nhạy cảm, khiêm tốn và luôn giúp đỡ người khác, trong khi 22/4 lại là một người quả quyết và quyết đoán hơn.</div>
                <div className='font-bold my-2'>Mục đích sống</div>
                <div>Những người này sinh ra với khả năng để làm việc với hoặc dưới sự chỉ dẫn của các nhà lãnh đạo năng động. Nếu thiếu đi điều này, họ sẽ thấy thực sự lạc lối. Họ không trở thành các nhà lãnh đạo, hiếm khi hoặc không muốn trở thành những người lãnh đạo, nhưng bù lại, họ có một khả năng tìm kiếm và làm việc được với nhiều kiểu người hoặc tổ chức mà sự chăm chỉ của họ được được đánh giá cao. Vai trò đặc biệt của họ là cung cấp sự hỗ trợ và sự trung thành.</div>
                <div className='font-bold my-2'>Thể hiện tốt nhất</div>
                <div>Mặc dù có năng lực và tự tin khi được làm việc một mình, Số 2 thường có xu hướng cảm thấy bất an nếu bị áp lựuc bởi các căng thẳng kéo dài. Họ phải được cho phép làm việc ở môi trường phù hợp với riêng họ. Họ đặc biệt không thích sự chính trực, ngay thẳng của họ bị nghi ngờ - điều này sẽ khiến họ đánh mất sự tự tin của bản thân mình. Điều thể hiện tốt nhất của họ là khả năng sử dụng đôi bàn tay của mình, như trong văn nghệ hay viết lách, nhưng luôn luôn được hướng dẫn bởi trực giác của họ.</div>

                <div>...</div>

                <div className='font-bold my-2'>{t('homepage_p2')}</div>

            </>
        )
    }

    commandText['eapod']={
        "command":"eapod 05/03/2022",
        "reply": (
            <>
                <div className='text-xl my-2 font-bold'>Interstellar Comet 2I Borisov</div>
                
                <div>
                    From somewhere else in the Milky Way galaxy, Comet 2I/Borisov was just visiting the Solar System. Discovered by amateur astronomer Gennady Borisov on August 30, 2019, the first known interstellar comet is seen in these two Hubble Space Telescope images from November and December 2019. On the left, a distant background galaxy near the line-of-sight to Borisov is blurred as Hubble tracked the speeding comet and dust tail about 327 million kilometers from Earth. At right, 2I/Borisov appears shortly after perihelion, its closest approach to Sun. European Southern Observatory observations indicate that this comet may never have passed close to any star before its 2019 perihelion passage. Borisov's closest approach to our fair planet, a distance of about 290 million kilometers, came on December 28, 2019. Even though Hubble's sharp images don't resolve the comet's nucleus, they did lead to estimates of less than 1 kilometer for its diameter.
                </div>
                
                <img src={apod} alt="" className='py-3 max-w-1/2'/>

                <div className="font-bold text-sm">Copyright: NASA • 05/03/2022</div>
            </>
        )
    }

    commandText['erand']={
        "command":"erand 100 200",
        "reply": (
            <>
                <div>Số ngẫu nhiên trong khoảng [100,200] là: 125</div>
            </>
        )
    }

    commandText['eloremipsum']={
        "command":"eloremipsum",
        "reply": (
            <>
                <div>
                    Dolore voluptate reprehenderit laboris voluptate esse aliqua veniam qui mollit irure velit in esse elit occaecat. Enim mollit nulla et deserunt enim do cupidatat ullamco duis eiusmod labore in eu. Occaecat id aliquip labore ullamco reprehenderit aliquip ad proident incididunt tempor minim. Incididunt Lorem ea culpa sunt incididunt eu aliqua nisi excepteur aliquip. Anim adipisicing consequat culpa veniam amet ipsum ad quis voluptate fugiat ad laboris.
                </div>
            </>
        )
    }

    commandText['epassword']={
        "command":"epassword 10",
        "reply": (
            <>
                <span>Mật khẩu tự động được tạo ra là: </span>
                <span className="bg-wheat-none text-white p-1">0]zA/1fQgG</span>
            </>
        )
    }

    commandText['edice']={
        "command":"edice 6 8 12",
        "reply": (
            <>
                <div>Đổ các xúc xắc 6 8 12 được kết quả lần lượt là 4 1 12. Tổng là: 17</div>
            </>
        )
    }

    const date = new Date()

    return (
        <>
            <Helmet>
                <meta charset="utf-8" />
                <title>Wheat Discord Bot | {t('homepage_title')}</title>
                <metadata
                name="description"
                content={t('homepage_desc')}
                />
                <link rel="canonical" href="https://www.wheatbot.xyz/" />

                <meta property="og:site_name" content="Wheat Discord Bot" />
                <meta property="og:url" content="https://www.wheatbot.xyz/" />
                <meta property="og:title" content="Wheat Discord Bot | Official Website" />
                <meta property="og:image" content={process.env.REACT_APP_URL_IMAGE} />
                <meta property="og:description" content="Latest Guidelines Statement of Wheat." />
            </Helmet>

            {/* NAVBAR + HERO */}

            <div className='flex flex-col h-screen bg-wheat-black bg-hero bg-contain bg-no-repeat bg-center bg-blend-screen'>
                <NavBar bg_color="wheat-black" text_color="white" hover_color="wheat-yellow2"/>
                {/* <div className="flex-1"> */}
                    <div className="flex-1 flex flex-col justify-evenly text-center"> 
                        <div className="self-center justify-self-center">
                            <div className="font-black text-center text-wheat-yellow1 font-extra text-4xl md:text-6xl lg:text-8xl">
                                MAGICAL LAND
                            </div>
                        </div>

                        <div className="self-center justify-self-center mx-8">
                            <div className="font-medium text-center text-yellow-100 font-large text-lg lg:text-2xl">
                                {t('homepage_p1')}
                            </div>
                        </div>

                        <div className="self-center justify-self-center">
                            <Link to='/invite'>
                                <div className={`border-4 border-wheat-yellow3 font-large font-semibold text-2xl md:ext-4xl rounded-full px-8 py-3 transition duration-500 text-wheat-yellow2 bg-transparent`}>
                                    {t('ADDTOSERVER')}
                                </div>                                
                            </Link>
                        </div>
                    </div>
                {/* </div> */}
            </div>
            
            {/* 5000 SERVERS */}

            <div className="grid grid-cols-10 grid-rows-10 lg:grid-rows-6 bg-wheat-yellow3 text-center h-screen px-8">
                <div className="col-start-2 col-span-8 row-start-2 row-span-1">
                    <div className="text-wheat-black font-extra text-xl lg:text-3xl border-t-2 border-b-2 border-wheat-black">
                        {t('homepage_p3')}
                    </div>
                </div>

                <div className="col-start-2 col-span-8 lg:col-start-2 lg:col-span-2 row-start-3 row-span-1 text-center">
                    <img className="max-h-full max-w-full mx-auto rounded" src="https://cdn.discordapp.com/icons/900618496403193907/a_71d9eee22d58128fba114bce46e56c81.jpg?size=160" alt=""/>
                </div>

                <div className="col-start-2 col-span-8 lg:col-start-5 lg:col-span-2 row-start-5 lg:row-start-3 row-span-1 text-center">
                    <img className="max-h-full max-w-full mx-auto rounded" src="https://cdn.discordapp.com/icons/338907205619286026/a_dea5e2b736b9e3a08f52702a32206361.webp?size=160" alt=""/>
                </div>

                <div className="col-start-2 col-span-8 lg:col-start-8 lg:col-span-2 row-start-7 lg:row-start-3 row-span-1 text-center">
                    <img className="max-h-full max-w-full mx-auto rounded" src='https://cdn.discordapp.com/icons/298413678213201921/a_3ae33dc7b2f6ddb60696985a4f446544.png?size=160' alt=""/>
                </div>

                <div className="col-start-2 col-span-8 lg:col-start-2 lg:col-span-2 row-start-4 row-span-1 lg:self-center text-center">
                    <div className='text-wheat-black font-large font-bold text-xl border-b-2 border-wheat-yellow4'>𝐀𝐯𝐨𝐜𝐚𝐝𝐨 𝐒𝐜𝐡𝐨𝐨𝐥</div>
                    <div className='text-wheat-black font-large font-semibold text-lg'>14000+ members</div>
                </div>

                <div className="col-start-2 col-span-8 lg:col-start-5 lg:col-span-2 row-start-6 lg:row-start-4 row-span-1 lg:self-center text-center">
                    <div className='text-wheat-black font-large font-bold text-xl border-b-2 border-wheat-yellow4'>VNC</div>
                    <div className='text-wheat-black font-large font-semibold text-lg'>7000+ members</div>
                </div>

                <div className="col-start-2 col-span-8 lg:col-start-8 lg:col-span-2 row-start-8 lg:row-start-4 row-span-1 lg:self-center text-center">
                    <div className='text-wheat-black font-large font-bold text-xl border-b-2 border-wheat-yellow4'>Reddit🇻🇳</div>
                    <div className='text-wheat-black font-large font-semibold text-lg'>3000+ members</div>
                </div>
                
                <div className="col-start-2 col-span-8 lg:col-start-3 lg:col-span-6 row-start-9 lg:row-start-5 row-span-1 lg:self-end">
                    <div className="text-wheat-black font-extra text-xl lg:text-2xl border-2 rounded p-1 border-wheat-black">
                        {t('homepage_p14')}
                    </div>
                </div>
            </div>

            {/* FORTUNE TELLING */}

            <div className="flex flex-col bg-wheat-black text-center h-fit py-20 px-10">
                <div className="">
                    <img className="h-16 w-auto" src={ftelling} alt=""/>
                </div>
                
                <div className="text-left py-3">
                    <div className="font-medium text-yellow-100 font-text text-xl">
                        Fortune Telling
                    </div>
                </div>

                <div className="text-left py-2"> 
                    <div className="font-bold text-left text-yellow-100 font-large text-5xl">
                        {t('homepage_p4')}
                    </div>
                </div>

                <div className="text-left py-2">
                    <div className="font-medium text-left text-yellow-100 font-text text-xl">
                        {t('homepage_p5')}
                    </div>
                </div>

                <div className="text-left py-2">
                    <a href="https://docs.wheatbot.xyz/commands/ftelling" target="_blank" rel="noreferrer">
                        <div className="border-2 border-wheat-yellow1 w-60 rounded bg-transparent">
                            <div className="font-bold text-center text-wheat-yellow1 font-text text-xl py-2">
                                {t('learnmore')}
                            </div>
                        </div>
                    </a>
                    
                </div>

                <div className="flex text-left py-5">
                     <div className={`${fortune==='etarot'?`bg-wheat-yellow1`:`bg-wheat-yellow3 hover:bg-wheat-yellow2`} w-40 py-2 mx-1 rounded-md cursor-pointer`} onClick={() => changeFortune('etarot')}>
                        <div className="font-bold text-center text-wheat-black font-large text-xl">
                                    etarot
                        </div>
                    </div>
                    
                    <div className={`${fortune==='ezodiac'?`bg-wheat-yellow1`:`bg-wheat-yellow3 hover:bg-wheat-yellow2`} w-40 py-2 mx-1 rounded-md cursor-pointer`} onClick={() => changeFortune('ezodiac')}>
                        <div className="font-bold text-center text-wheat-black font-large text-xl">
                                ezodiac
                        </div>
                    </div>                    

                    <div className={`${fortune==='enumerology'?`bg-wheat-yellow1`:`bg-wheat-yellow3 hover:bg-wheat-yellow2`} w-40 py-2 mx-1 rounded-md cursor-pointer`} onClick={() => changeFortune('enumerology')}>
                        <div className="font-bold text-center text-wheat-black font-large text-xl">
                            enumerology
                        </div>
                    </div>
                </div>

                <div>
                    <div>
                        
                    </div>
                    
                    <div className="flex flex-col bg-wheat-brown1 overflow-auto rounded-sm border-2 border-wheat-yellow1 h-96">
                    <div className="flex p-4">
                            <div className="text-left">
                                <img src={duser} alt="" className="w-12 md:w-16 lg:w-20 rounded-full"/>
                            </div>
 
                            <div className="flex flex-col w-4/6 pl-5">
                                <div className='text-left'>
                                    <span className='font-text text-white text-2xl font-semibold'>
                                        User
                                    </span>

                                    <span className='font-text text-gray-100 ml-3 p-1 text-sm font-medium rounded-sm'>
                                        Today at {date.getHours()<10?`0${date.getHours()}`:date.getHours()}:{date.getMinutes()<10?`0${date.getMinutes()}`:date.getMinutes()}
                                    </span>
                                </div>
                                <div className='text-white text-xl py-3 text-left font-text'>
                                    {commandText[fortune].command}
                                </div>
                            </div>   
                        </div>

                        <div className="flex p-4">
                            <div className="text-left">
                                <img src={WHEAT} alt="" className="w-12 md:w-16 lg:w-20"/>
                            </div>
 
                            <div className="flex flex-col w-4/6 pl-5">
                                <div className='text-left'>
                                    <span className='font-text text-white text-2xl font-semibold'>
                                        Wheat
                                    </span>

                                    <span className='font-text text-white ml-3 p-1 bg-blue-700 text-base font-medium rounded-sm'>
                                        BOT
                                    </span>

                                    <span className='font-text text-gray-100 ml-3 p-1 text-sm font-medium rounded-sm'>
                                        Today at {date.getHours()<10?`0${date.getHours()}`:date.getHours()}:{date.getMinutes()<10?`0${date.getMinutes()}`:date.getMinutes()}
                                    </span>
                                </div>
                                <div className='bg-wheat-black mt-5 border-l-4 border-yellow-400 text-white p-3 text-left font-text'>
                                    {commandText[fortune].reply}
                                </div>
                            </div>   
                        </div>
                    </div>

                </div>

                
            </div>
            
            {/* ASTRONOMY */}

            <div className="flex flex-col bg-wheat-black text-center h-fit py-20 px-10">
                <div className="">
                    <img className="h-16 w-auto" src={astronomy} alt=""/>
                </div>
                
                <div className="text-left py-3">
                    <div className="font-medium text-yellow-100 font-text text-xl">
                        Astronomy
                    </div>
                </div>

                <div className="text-left py-2"> 
                    <div className="font-bold text-left text-yellow-100 font-large text-5xl">
                        {t('homepage_p6')}
                    </div>
                </div>

                <div className="text-left py-2">
                    <div className="font-medium text-left text-yellow-100 font-text text-xl">
                        {t('homepage_p7')}
                    </div>
                </div>

                <div className="text-left py-2">
                    <a href="https://docs.wheatbot.xyz/commands/astronomy" target="_blank" rel="noreferrer">
                        <div className="border-2 border-wheat-yellow1 w-60 rounded bg-transparent">
                            <div className="font-bold text-center text-wheat-yellow1 font-text text-xl py-2">
                                {t('learnmore')}
                            </div>
                        </div>
                    </a>
                    
                </div>

                <div className="pt-5">
                    <div>
                        
                    </div>
                    
                    <div className="flex flex-col bg-wheat-brown1 overflow-auto rounded-sm border-2 border-wheat-yellow1 h-96">
                        <div className="flex p-4">
                            <div className="text-left">
                                <img src={duser} alt="" className="w-12 md:w-16 lg:w-20 rounded-full"/>
                            </div>
 
                            <div className="flex flex-col w-4/6 pl-5">
                                <div className='text-left'>
                                    <span className='font-text text-white text-2xl font-semibold'>
                                        User
                                    </span>

                                    <span className='font-text text-gray-100 ml-3 p-1 text-sm font-medium rounded-sm'>
                                        Today at {date.getHours()<10?`0${date.getHours()}`:date.getHours()}:{date.getMinutes()<10?`0${date.getMinutes()}`:date.getMinutes()}
                                    </span>
                                </div>
                                <div className='text-white text-xl py-3 text-left font-text'>
                                    {commandText['eapod'].command}
                                </div>
                            </div>   
                        </div>

                        <div className="flex p-4">
                            <div className="text-left">
                                <img src={WHEAT} alt="" className="w-12 md:w-16 lg:w-20"/>
                            </div>
 
                            <div className="flex flex-col w-4/6 pl-5">
                                <div className='text-left'>
                                    <span className='font-text text-white text-2xl font-semibold'>
                                        Wheat
                                    </span>

                                    <span className='font-text text-white ml-3 p-1 bg-blue-700 text-base font-medium rounded-sm'>
                                        BOT
                                    </span>

                                    <span className='font-text text-gray-100 ml-3 p-1 text-sm font-medium rounded-sm'>
                                        Today at {date.getHours()<10?`0${date.getHours()}`:date.getHours()}:{date.getMinutes()<10?`0${date.getMinutes()}`:date.getMinutes()}
                                    </span>
                                </div>
                                <div className='bg-wheat-black mt-5 border-l-4 border-yellow-400 text-white p-3 text-left font-text'>
                                    {commandText['eapod'].reply}
                                </div>
                            </div>   
                        </div>
                    </div>

                </div>

                
            </div>
            
            {/* RANDOM */}

            <div className="flex flex-col bg-wheat-black text-center h-fit py-20 px-10">
                <div className="">
                    <img className="h-16 w-auto" src={randomm} alt=""/>
                </div>
                
                <div className="text-left py-3">
                    <div className="font-medium text-yellow-100 font-text text-xl">
                        Random
                    </div>
                </div>

                <div className="text-left py-2"> 
                    <div className="font-bold text-left text-yellow-100 font-large text-5xl">
                        {t('homepage_p8')}
                    </div>
                </div>

                <div className="text-left py-2">
                    <div className="font-medium text-left text-yellow-100 font-text text-xl">
                        {t('homepage_p9')}
                    </div>
                </div>

                <div className="text-left py-2">
                    <a href="https://docs.wheatbot.xyz/commands/random" target="_blank" rel="noreferrer">
                        <div className="border-2 border-wheat-yellow1 w-96 rounded bg-transparent">
                            <div className="font-bold text-center text-wheat-yellow1 font-text text-xl py-2">
                                {t('homepage_p10')}
                            </div>
                        </div>
                    </a>
                    
                </div>

                <div className="flex text-left py-5">
                     <div className={`${random==='eloremipsum'?`bg-wheat-yellow1`:`bg-wheat-yellow3 hover:bg-wheat-yellow2`} w-40 py-2 mx-1 rounded-md cursor-pointer`} onClick={() => changeRandom('eloremipsum')}>
                        <div className="font-bold text-center text-wheat-black font-large text-xl">
                            eloremipsum
                        </div>
                    </div>
                    
                    <div className={`${random==='erand'?`bg-wheat-yellow1`:`bg-wheat-yellow3 hover:bg-wheat-yellow2`} w-40 py-2 mx-1 rounded-md cursor-pointer`} onClick={() => changeRandom('erand')}>
                        <div className="font-bold text-center text-wheat-black font-large text-xl">
                            erand
                        </div>
                    </div>                    

                    <div className={`${random==='epassword'?`bg-wheat-yellow1`:`bg-wheat-yellow3 hover:bg-wheat-yellow2`} w-40 py-2 mx-1 rounded-md cursor-pointer`} onClick={() => changeRandom('epassword')}>
                        <div className="font-bold text-center text-wheat-black font-large text-xl">
                            epassword
                        </div>
                    </div>

                    <div className={`${random==='edice'?`bg-wheat-yellow1`:`bg-wheat-yellow3 hover:bg-wheat-yellow2`} w-40 py-2 mx-1 rounded-md cursor-pointer`} onClick={() => changeRandom('edice')}>
                        <div className="font-bold text-center text-wheat-black font-large text-xl">
                            edice
                        </div>
                    </div>
                </div>

                <div>
                    <div>
                        
                    </div>
                    
                    <div className="flex flex-col bg-wheat-brown1 overflow-auto rounded-sm border-2 border-wheat-yellow1 h-96">
                    <div className="flex p-4">
                            <div className="text-left">
                                <img src={duser} alt="" className="w-12 md:w-16 lg:w-20 rounded-full"/>
                            </div>
 
                            <div className="flex flex-col w-4/6 pl-5">
                                <div className='text-left'>
                                    <span className='font-text text-white text-2xl font-semibold'>
                                        User
                                    </span>

                                    <span className='font-text text-gray-100 ml-3 p-1 text-sm font-medium rounded-sm'>
                                        Today at {date.getHours()<10?`0${date.getHours()}`:date.getHours()}:{date.getMinutes()<10?`0${date.getMinutes()}`:date.getMinutes()}
                                    </span>
                                </div>
                                <div className='text-white text-xl py-3 text-left font-text'>
                                    {commandText[random].command}
                                </div>
                            </div>   
                        </div>

                        <div className="flex p-4">
                            <div className="text-left">
                                <img src={WHEAT} alt="" className="w-12 md:w-16 lg:w-20"/>
                            </div>
 
                            <div className="flex flex-col w-4/6 pl-5">
                                <div className='text-left'>
                                    <span className='font-text text-white text-2xl font-semibold'>
                                        Wheat
                                    </span>

                                    <span className='font-text text-white ml-3 p-1 bg-blue-700 text-base font-medium rounded-sm'>
                                        BOT
                                    </span>

                                    <span className='font-text text-gray-100 ml-3 p-1 text-sm font-medium rounded-sm'>
                                        Today at {date.getHours()<10?`0${date.getHours()}`:date.getHours()}:{date.getMinutes()<10?`0${date.getMinutes()}`:date.getMinutes()}
                                    </span>
                                </div>
                                <div className='bg-wheat-black mt-5 border-l-4 border-yellow-400 text-white p-3 text-left font-text'>
                                    {commandText[random].reply}
                                </div>
                            </div>   
                        </div>
                    </div>

                </div>

                
            </div>
            
            {/* SEE MORE */}

            <div className="grid grid-cols-5 grid-rows-3 bg-wheat-yellow3 text-center h-96 p-8">
                <div className="row-start-1 row-span-2 col-start-1 col-span-5 font-extra text-center text-lg md:text-2xl font-bold md:px-10 self-center">
                    {t('homepage_p11')}
                </div>

                <div className="row-start-3 row-span-1 col-start-1 col-span-2 md:col-start-2 md:col-span-1 self-end md:self-start">
                    <Link to='/invite'>
                        <div className={`border-4 border-wheat-black font-large font-semibold text-base md:text-xl rounded-full lg:px-8 py-3 transition duration-500 text-wheat-black bg-transparent`}>
                            {t('addtoserver')}
                        </div>
                    </Link>
                </div>

                <div className="row-start-3 row-span-1 col-start-4 col-span-2 md:col-start-4 md:col-span-1 self-end md:self-start">
                    <a href="https://docs.wheatbot.xyz/" target="_blank" rel="noreferrer">
                        <div className={`border-4 border-wheat-black font-large font-semibold text-base md:text-xl rounded-full lg:px-8 py-3 transition duration-500 text-wheat-yellow3 bg-wheat-black`}>
                            {t('documentation')}
                        </div>
                    </a>
                </div>
            </div>

        </>
    )
}

export default Homepage

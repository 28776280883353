import React from "react"
import { useTranslation } from "react-i18next"
import NavBar from "../components/layout/NavBar"
import discord from '../assets/discord.svg'
import faqs from '../assets/faqs.svg'
import bugs from '../assets/bugs.svg'
import mail from '../assets/mail.svg'
import ticket from '../assets/ticket.svg'
import docs from '../assets/docs.svg'
import {Helmet} from "react-helmet"

const Support = () => {
    const { t } = useTranslation()
    return (
        <>
            <Helmet>
                <meta charset="utf-8" />
                <title>{t('support_mtitle')} | Wheat Discord Bot</title>
                <metadata
                name="description"
                content={t('support_desc')}
                />
                <link rel="canonical" href="https://www.wheatbot.xyz/support" />

                <meta property="og:site_name" content="Wheat Discord Bot" />
                <meta property="og:url" content="https://www.wheatbot.xyz/support" />
                <meta property="og:title" content="Support | Wheat Discord Bot" />
                <meta property="og:image" content={process.env.REACT_APP_URL_IMAGE} />
                <meta property="og:description" content="Need help? See some our support details!" />
            </Helmet>

            <NavBar bg_color="wheat-black" text_color="white" hover_color="wheat-yellow2"/>

            <div className="py-10 px-8 text-center bg-white h-auto">
                <div className="font-extra text-4xl md:text-6xl text-wheat-black pt-5 pb-10">
                    {t('support_p1')}
                </div>

                <div className="px-10 grid grid-cols-4">
                    <div className="col-start-1 col-span-4 md:col-span-1 row-start-1 row-span-1">
                        <div className="m-5">
                            <a target="_blank" rel="noopener noreferrer" href="https://discord.gg/z5Z4uzmED9">
                                <div className="bg-yellow-100 hover:bg-wheat-yellow2 p-2 h-52 md:h-64 lg:h-80 text-center">
                                    <img src={discord} alt="" className="my-3 md:mt-10 md:my-5 h-24 md:h-32 mx-auto"/>
                                    <div className="text-wheat-gray pb-2 text-2xl font-large">
                                        {t('support_p2')}
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>

                    <div className="col-start-1 md:col-start-2 col-span-4 md:col-span-1 row-start-2 md:row-start-1 row-span-1">
                        <div className="m-5">
                            <a target="_blank" rel="noopener noreferrer" href="https://docs.google.com/forms/d/1EwycxNOkf0lJasyiyDj6G1AT9CDSJtvcLwYTcF9dk9c/viewform?edit_requested=true">
                                <div className="bg-yellow-100 hover:bg-wheat-yellow2 p-2 h-52 md:h-64 lg:h-80">
                                    <img src={ticket} alt="" className="my-3 md:mt-10 md:my-5 h-24 md:h-32 mx-auto"/>
                                    <div className="text-wheat-gray pb-2 md:text-xl lg:text-2xl font-large">
                                        {t('support_p4')}
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>

                    <div className="col-start-1 md:col-start-3 col-span-4 md:col-span-1 row-start-3 md:row-start-1 row-span-1">
                        <div className="m-5">
                            <a target="_blank" rel="noopener noreferrer" href={t('code')==='en'?"https://docs.wheatbot.xyz/faqs":"https://docs.wheatbot.xyz/vi/faqs"}>
                                <div className="bg-yellow-100 hover:bg-wheat-yellow2 p-2 h-52 md:h-64 lg:h-80">
                                    <img src={faqs} alt="" className="my-3 md:mt-10 md:my-5 h-24 md:h-32 mx-auto"/>
                                    <div className="text-wheat-gray pb-2 md:text-xl lg:text-2xl font-large">
                                        {t('support_p6')}
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>

                    <div className="col-start-1 md:col-start-4 col-span-4 md:col-span-1 row-start-4 md:row-start-1 row-span-1">
                        <div className="m-5">
                            <a target="_blank" rel="noopener noreferrer" href="https://docs.google.com/forms/d/1QOYrbwJqjZHZElWbq7FIb5HEzsRPJN-PBxx_5hiv5nQ/viewform?edit_requested=true">
                                <div className="bg-yellow-100 hover:bg-wheat-yellow2 p-2 h-52 md:h-64 lg:h-80">
                                    <img src={bugs} alt="" className="my-3 md:mt-10 md:my-5 h-24 md:h-32 mx-auto"/>
                                    <div className="text-wheat-gray pb-2 md:text-xl lg:text-2xl font-large">
                                        {t('support_p8')}
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>

                    <div className="col-start-1 md:col-start-2 col-span-4 md:col-span-1 row-start-5 md:row-start-2 row-span-1">
                        <div className="m-5">
                            <a target="_blank" rel="noopener noreferrer" href={t('code')==='en'?"https://docs.wheatbot.xyz":"https://docs.wheatbot.xyz/vi"}>
                                <div className="bg-yellow-100 hover:bg-wheat-yellow2 p-2 h-52 md:h-64 lg:h-80">
                                    <img src={docs} alt="" className="my-3 md:mt-10 md:my-5 h-24 md:h-32 mx-auto"/>
                                    <div className="text-wheat-gray pb-2 md:text-xl lg:text-2xl font-large">
                                        {t('support_p10')}
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>

                    <div className="col-start-1 md:col-start-3 col-span-4 md:col-span-1 row-start-6 md:row-start-2 row-span-1">
                        <div className="m-5">
                            <a target="_blank" rel="noopener noreferrer" href="mailto:coolscp2005@gmail.com">
                                <div className="bg-yellow-100 hover:bg-wheat-yellow2 p-2 h-52 md:h-64 lg:h-80">
                                    <img src={mail} alt="" className="my-3 md:mt-10 md:my-5 h-24 md:h-32 mx-auto"/>
                                    <div className="text-wheat-gray pb-2 md:text-xl lg:text-2xl font-large">
                                        {t('support_p11')}
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            
        </>
    )
}

export default Support

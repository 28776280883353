import React from "react"
import { useTranslation } from "react-i18next"
import NavBar from '../components/layout/NavBar'
import {Helmet} from "react-helmet"

const TermsOfUse = () => {
    const { t } = useTranslation()

    return (
        <>
            <Helmet>
                <meta charset="utf-8" />
                <title>{t('terms_mtitle')} | Wheat Discord Bot</title>
                <metadata
                name="description"
                content={t('terms_desc')}
                />
                <link rel="canonical" href="https://www.wheatbot.xyz/terms" />

                <meta property="og:site_name" content="Wheat Discord Bot" />
                <meta property="og:url" content="https://www.wheatbot.xyz/terms" />
                <meta property="og:title" content="Terms Of Use | Wheat Discord Bot" />
                <meta property="og:image" content={process.env.REACT_APP_URL_IMAGE} />
                <meta property="og:description" content="Latest Terms Of Use Statement of Wheat." />
            </Helmet>

            <NavBar bg_color="white" text_color="wheat-black" hover_color="wheat-yellow4"/>

            <div className="px-10 lg:px-32 py-20 font-text bg-white ">
                <div className="text-4xl md:text-6xl font-black font-extra">{t("terms_title")}</div>

                <div className="text-2xl pt-10">{t("terms_time")}</div>
                
                <div className="text-lg">{t("terms_p1")}</div>
            
                <div className="text-3xl font-medium pt-5">{t("terms_p2")}</div>

                <div className="text-2xl font-medium pt-5">{t("terms_p3")}</div>
                <ul className="list-inside list-disc">
                    <li className="text-lg">{t("terms_p4")}</li>
                    <li className="text-lg">{t("terms_p5")}</li>
                    <li className="text-lg">{t("terms_p6")}</li>
                    <li className="text-lg">{t("terms_p7")}</li>
                    <li className="text-lg">{t("terms_p8")}</li>
                    <li className="text-lg">{t("terms_p9")}</li>
                </ul>

                <div className="text-3xl font-medium pt-5">{t("terms_p10")}</div>
                <ul className="list-inside list-disc">
                    <li className="text-lg">{t("terms_p11")}</li>
                    <li className="text-lg">{t("terms_p12")}</li>
                </ul>
                <div className="text-3xl font-medium pt-5">{t("terms_p13")}</div>

                <div className="text-lg">{t("terms_p14")}</div>

                <div className="text-3xl font-medium pt-5">{t("terms_p15")}</div>
                <ul className="list-inside list-disc">
                    <li className="text-lg">{t("terms_p16")}</li>
                    <li className="text-lg">{t("terms_p17")}</li>
                    <li className="text-lg">{t("terms_p18")}</li>
                </ul>
                <div className="text-3xl font-medium pt-5">{t("terms_p19")}</div>
                <div className="text-lg">{t("terms_p20")}</div>
                <div className="text-3xl font-medium pt-5">{t("terms_p21")}</div>
                <ul className="list-inside list-disc">
                    <li className="text-lg">{t("terms_p22")}</li>
                    <li className="text-lg">{t("terms_p23")}</li>
                </ul>
                <div className="text-3xl font-medium pt-5">{t("terms_p24")}</div>
                <ul className="list-inside list-disc">
                    <li className="text-lg">{t("terms_p25")}</li>
                    <li className="text-lg">{t("terms_p26")}</li>
                </ul>
                <div className="text-3xl font-medium pt-5">{t("terms_p27")}</div>
                <ul className="list-inside list-disc">
                    <li className="text-lg">{t("terms_p28")}</li>
                    <li className="text-lg">{t("terms_p29")}</li>
                    <li className="text-lg">{t("terms_p30")}</li>
                </ul>
                <div className="text-3xl font-medium pt-5">{t("terms_p31")}</div>
                <ul className="list-inside list-disc">
                    <li className="text-lg">{t("terms_p32")}</li>
                    <li className="text-lg">{t("terms_p33")}</li>
                </ul>
                <div className="text-3xl font-medium pt-5">{t("terms_p34")}</div>

                <div className="text-lg">
                {t("terms_p35")}<br />
                {t("terms_p36")}<br />
                {t("terms_p37")}
                </div>
                
                <div className="text-lg">
                    {t("terms_p38")}<br />
                    {t('terms_p39')} <strong>coolscp2005@gmail.com</strong></div>

            </div>
        </>
    )
}

export default TermsOfUse

import { Disclosure } from '@headlessui/react'
import logo from '../../assets/wheat_official.svg'
import { useTranslation } from "react-i18next"
import {Link} from 'react-router-dom'

const NavBar = (props) => {
    const { t } = useTranslation()

    return (
        <>
            <Disclosure as="nav" className={`bg-${props.bg_color}`}>
                {({open}) => (
                    <>
                        <div className='max-w-7xl mx-auto py-3 px-3 md:px-10'>
                            <div className='flex items-center h-16'>
                                <div className="inset-y-0 left-0 flex items-center md:hidden">
                                    <Disclosure.Button className={`items-center justify-center w-10 p-2 rounded-md text-${props.text_color}`}>
                                        {open ? (
                                            <i className={`fa fa-times fa-2x text-${props.text_color}`} aria-hidden="true" />
                                        ) : (
                                            <i className={`fa fa-bars fa-2x text-${props.text_color}`} aria-hidden="true" />
                                        )}
                                    </Disclosure.Button>
                                </div>

                                <div className='flex-1'>
                                    <div className='flex ml-5 md:ml-0 justify-between'>
                                        <Link to="/">
                                            <img
                                                className="h-8 w-auto"
                                                src={logo}
                                                alt=""
                                            />
                                        </Link>
                                        
                                        <div className='hidden md:flex font-text font-semibold text-base justify-center space-x-10 flex-1'>
                                            <a href={t('code')==='en'?"https://docs.wheatbot.xyz/":"https://docs.wheatbot.xyz/vi"} className={`text-${props.text_color} transition duration-500 hover:text-${props.hover_color}`}>
                                                {t('documentation')}
                                            </a>
                                            
                                            <Link to='/support' className={`text-${props.text_color} transition duration-500 hover:text-${props.hover_color}`}>
                                                {t('support')}
                                            </Link>

                                            <Link to='/donate' className={`text-${props.text_color} transition duration-500 hover:text-${props.hover_color}`}>
                                                {t('donate')}
                                            </Link>

                                            <Link to='/status' className={`text-${props.text_color} transition duration-500 hover:text-${props.hover_color}`}>
                                                {t('status')}
                                            </Link>

                                            <Link to='/thanks' className={`text-${props.text_color} transition duration-500 hover:text-${props.hover_color}`}>
                                                {t('thanks')}
                                            </Link>
                                        </div>

                                        <Link to='/invite'>
                                            <div className={`border-2 border-${props.bg_color} font-text font-semibold text-base text-${props.bg_color} bg-${props.hover_color} rounded-full px-4 py-1 transition duration-500 hover:bg-transparent hover:text-${props.text_color} hover:border-${props.hover_color}`}>
                                                {t('addtoserver')}
                                            </div>                                
                                        </Link>

                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <Disclosure.Panel>
                            <div className='pl-16 pb-5'>
                                <div className='flex flex-col font-text font-semibold text-lg justify-center'>
                                    <a href={t('code')==='en'?"https://docs.wheatbot.xyz/":"https://docs.wheatbot.xyz/vi"} className={`text-${props.text_color} transition duration-500 hover:text-${props.hover_color}`}>
                                        {t('documentation')}
                                    </a>
                                    
                                    <Link to='/support' className={`text-${props.text_color} transition duration-500 hover:text-${props.hover_color}`}>
                                        {t('support')}
                                    </Link>

                                    <Link to='/donate' className={`text-${props.text_color} transition duration-500 hover:text-${props.hover_color}`}>
                                        {t('donate')}
                                    </Link>

                                    <Link to='/status' className={`text-${props.text_color} transition duration-500 hover:text-${props.hover_color}`}>
                                        {t('status')}
                                    </Link>

                                    <Link to='/thanks' className={`text-${props.text_color} transition duration-500 hover:text-${props.hover_color}`}>
                                        {t('thanks')}
                                    </Link>
                                </div>
                            </div>
                            
                        </Disclosure.Panel>

                    </>
                )}
            </Disclosure>
        </>
    )
}

export default NavBar


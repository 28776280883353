import './App.css'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import PublicRoute from './components/route/PublicRoute'
import SpecialRoute from './components/route/SpecialRoute'
import Homepage from './views/Homepage'
import Invite from './components/misc/Invite'
import Privacy from './views/Privacy'
import Disclaimer from './views/Disclaimer'
import Donate from './views/Donate'
import Guidelines from './views/Guidelines'
import Support from './views/Support'
import TermsOfUse from './views/TermsOfUse'
import Brand from './views/Brand'
import ScrollToTop from './components/route/ScrollToTop'
import RedirectRoute from './components/route/RedirectRoute'
import Changelog from './views/Changelog'

function App() {
  return (
    <Router>
      <ScrollToTop />
        <Switch>
          <PublicRoute exact path='/' component={Homepage}/>
          <PublicRoute exact path='/privacy' component={Privacy}/>
          <PublicRoute exact path='/changelog' component={Changelog}/>
          <RedirectRoute path='/invite' component={Invite}/>
          <PublicRoute exact path='/disclaimer' component={Disclaimer}/>
          <RedirectRoute exact path='/donate' component={Donate}/>
          <PublicRoute exact path='/guidelines' component={Guidelines}/>
          <PublicRoute exact path='/support' component={Support}/>
          <PublicRoute exact path='/terms' component={TermsOfUse}/>
          <SpecialRoute exact path='/brand' component={Brand} special='true'/>
        </Switch>
    </Router>
  )
}

export default App;

import {Route} from 'react-router-dom'

const RedirectRoute = ({component:Component,...rest}) => {
    return (
        <>
            <Route {...rest} render={props => {
                return (
                <>
                    <Component {...rest} {...props} /> 
                </>
            )}} />
        </>
    )
}

export default RedirectRoute

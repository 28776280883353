import { useTranslation } from "react-i18next"
import {Helmet} from "react-helmet"

const Invite = () => {
    const { t } =  useTranslation()
    window.location = "https://discord.com/oauth2/authorize?client_id=786234973308715008&permissions=535193582704&scope=bot%20applications.commands";
    return (
        <>
            <Helmet>
                <meta charset="utf-8" />
                <title>{t('donate_title')} | Wheat Discord Bot</title>
                <metadata
                name="description"
                content={t('donate_desc')}
                />
                <link rel="canonical" href="https://www.wheatbot.xyz/donate" />

                <meta property="og:site_name" content="Wheat Discord Bot" />
                <meta property="og:url" content="https://www.wheatbot.xyz/donate" />
                <meta property="og:title" content="Donate | Wheat Discord Bot" />
                <meta property="og:image" content={process.env.REACT_APP_URL_IMAGE} />
                <meta property="og:description" content="Invite bot!" />
            </Helmet>
        </>
    )
}

export default Invite

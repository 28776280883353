import logo from "../../assets/wheat_official.svg"
import { Listbox } from '@headlessui/react'
import {Link} from 'react-router-dom'
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

const Footer = (props) => {
    window.scrollTo(0, 0)
    const { t, i18n } = useTranslation()
    const language = t('code')
    const [selected, setSelected] = useState(language)
    
    useEffect(() => {

    })
    
    const change = (e) => {
        setSelected(e)
        i18n.changeLanguage(e)
    }

    return (
        <>
            <div className={`bg-${props.bg_color}`}>
                <script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js"> </script>
                <div className={`pt-16 pb-8 px-10 font-text`}>
                    <div className="grid grid-cols-6 grid-rows-2 md:grid-rows-1 gap-3">
                        <div className="row-start-1 md:row-span-2 col-start-1 md:col-span-2 col-span-6">
                            <div className="flex flex-col">
                                <div className={`font-extra font-black text-3xl md:text-4xl text-${props.slo_color} text-center md:text-left`}>
                                    MAGICAL LAND
                                </div>

                                <div className="mt-4 text-center md:text-left">
                                    <a href="https://www.facebook.com/wheat1261"> 
                                        <i className={`fa fa-facebook-square fa-2x text-${props.text_color} mr-3`} aria-hidden="true"></i>
                                    </a>

                                    <a href="https://github.com/Wheat-Discord-Bot"> 
                                        <i className={`fa fa-github-square fa-2x text-${props.text_color} mx-3`} aria-hidden="true"></i>
                                    </a>

                                </div>
                                    
                                <Listbox value={selected} onChange={e => change(e)} >
                                    <div className="relative text-center md:text-left">
                                        <Listbox.Button className={`relative w-full py-2 pr-10 text-left text-lg text-${props.text_color} text-center md:text-left`}>
                                            <span className="block truncate font-text">
                                                <i className="fa fa-globe fa-2"></i> ▻ {t(selected)}
                                            </span>
                                        </Listbox.Button>
                                        
                                        <Listbox.Options className={`absolute text-lg bg-${props.text_color} shadow-lg max-h-60 md:w-40 w-full`}>
                                            <Listbox.Option
                                                key='1'
                                                className={({ active }) =>
                                                    `${active ? `bg-${props.light_color}` : `text-${props.bg_color}`} select-none relative py-2 pl-10 pr-4`
                                                }
                                                value='vi'
                                            >
                                                {({ selected }) => (
                                                    <>
                                                        <span
                                                            className={`${
                                                            selected ? 'font-medium' : 'font-normal'
                                                            } block truncate`}
                                                        >
                                                            {t('vi')}
                                                        </span>
                                                    </>
                                                )}
                                            </Listbox.Option>
                                            <Listbox.Option
                                                key='0'
                                                className={({ active }) =>
                                                    `${active ? `bg-${props.light_color}` : `text-${props.bg_color}`} select-none relative py-2 pl-10 pr-4`
                                                }
                                                value='en'
                                            >
                                                {({ selected }) => (
                                                    <>
                                                        <span
                                                            className={`${
                                                            selected ? 'font-medium' : 'font-normal'
                                                            } block truncate`}
                                                        >
                                                            {t('en')}
                                                        </span>
                                                    </>
                                                )}
                                            </Listbox.Option>
                                        </Listbox.Options>
                                    </div>
                                </Listbox>

                                <div className={`text-${props.text_color} font-text text-center md:text-left`}>
                                    {t('copyright')}
                                </div>

                                <div className="pt-4 w-32 hidden md:block">
                                    <a href="//www.dmca.com/Protection/Status.aspx?ID=182f1e05-85ea-4d1d-b463-0a4b9da82621" title="DMCA.com Protection Status" class="dmca-badge"> 
                                        <img src="https://images.dmca.com/Badges/dmca_protected_17_120.png?ID=182f1e05-85ea-4d1d-b463-0a4b9da82621" alt="DMCA.com Protection Status" />
                                    </a>  
                                </div>

                                

                            </div>
                        </div>
                            
                        <div className="row-start-2 row-span-1 col-start-1 col-span-2 md:row-start-1 md:row-span-2 md:col-start-4 md:col-span-1">
                            <div className={`text-${props.border_color} mb-3 font-bold`}>
                                {t('service')}
                            </div>

                            <Link to='/brand'>
                                <div className={`text-${props.text_color}  mb-2`}>
                                    {t('brand')}
                                </div>
                            </Link>
                        </div>
                            
                        <div className="row-start-2 row-span-1 col-start-3 col-span-2 md:row-start-1 md:row-span-2 md:col-start-5 md:col-span-1">
                            <div className={`text-${props.border_color} mb-3 font-bold`}>
                                {t('about')}
                            </div>

                            <Link to='/support'>
                                <div className={`text-${props.text_color} mb-2`}>
                                    {t('support')} 
                                </div>
                            </Link>

                            <a href={t('code')==='en'?"https://docs.wheatbot.xyz/":"https://docs.wheatbot.xyz/vi"}>
                                <div className={`text-${props.text_color} mb-2`}>
                                    {t('documentation')}
                                </div>
                            </a>

                            <a href={t('code')==='en'?"https://docs.wheatbot.xyz/faqs":"https://docs.wheatbot.xyz/vi/faqs"}>
                                <div className={`text-${props.text_color}  mb-2`}>
                                    {t('faqs')}
                                </div>
                            </a>

                            <Link to='/changelog'>
                                <div className={`text-${props.text_color} mb-2`}>
                                    {t('changelog')} 
                                </div>
                            </Link>

                            <Link to='/status'>
                                <div className={`text-${props.text_color} mb-2`}>
                                    {t('status')} 
                                </div>
                            </Link>
                        </div>

                        <div className="row-start-2 row-span-1 col-start-5 col-span-2 md:row-start-1 md:row-span-2 md:col-start-6 md:col-span-1">
                            <div className={`text-${props.border_color} mb-3 font-bold`}>
                                    {t('legal')}
                            </div>

                            <Link to='/terms'>
                                <div className={`text-${props.text_color}  mb-2`}>
                                    {t('tou')}
                                </div>
                            </Link>

                            <Link to='/privacy'>
                                <div className={`text-${props.text_color}  mb-2`}>
                                    {t('privacy')} 
                                </div>
                            </Link>

                            <Link to='/guidelines'>
                                <div className={`text-${props.text_color} mb-2`}>
                                    {t('guidelines')}
                                </div>
                            </Link>

                            <Link to='/disclaimer'>
                                <div className={`text-${props.text_color} mb-2`}>
                                    {t('disclaimer')}
                                </div>
                            </Link>
                        </div>
                    </div>

                    <div className="pt-4 w-32 block md:hidden mx-auto">
                        <a href="//www.dmca.com/Protection/Status.aspx?ID=182f1e05-85ea-4d1d-b463-0a4b9da82621" title="DMCA.com Protection Status" class="dmca-badge"> 
                            <img src="https://images.dmca.com/Badges/dmca_protected_17_120.png?ID=182f1e05-85ea-4d1d-b463-0a4b9da82621" alt="DMCA.com Protection Status" />
                        </a>  
                    </div>


                </div>

                <hr className={`border-${props.border_color} mx-10 border-t-2`}/>

                <div className="pb-6">
                    <img src={logo} className="mt-5 mx-auto h-20 w-auto"/>
                </div>
            </div>
        </>
    )
}

export default Footer

